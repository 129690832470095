import { styled } from '@linaria/react';

export const StyledAccountSetupAvatar = styled.div`
  display: flex;
  gap: var(--spacing-sm);
`;

export const EmailNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
