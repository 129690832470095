import { useMemo } from 'react';

import { useInputValue } from '@/modules/form/input';
import { useUser } from '@/services/api/auth/auth.service.hook';

import { AccountForm } from '../account.form';
import { AccountSetupAvatar } from '../components/AccountSetupAvatar/AccountSetupAvatar';

export interface AccountSetupAvatarContainerProps {
  form: AccountForm;
}

export function AccountSetupAvatarContainer({ form }: AccountSetupAvatarContainerProps) {
  const user = useUser();

  const email = useMemo(() => user?.communicationChannels.email ?? '', [user]);
  const firstName = useInputValue(form, 'firstName');
  const lastName = useInputValue(form, 'lastName');

  return <AccountSetupAvatar email={email} firstName={firstName} lastName={lastName} />;
}
