import { styled } from '@linaria/react';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: var(--spacing-3xl);

  body[data-mobile='true'] & {
    padding: var(--spacing-md);
  }
`;

export const PageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-3xl) var(--spacing-3xl);
  width: 100%;
  max-width: 600px;

  body[data-mobile='true'] & {
    margin-top: auto;
    padding: var(--spacing-md);
    border-top: 1px solid var(--border-hairline);
    box-shadow: var(--elevation-top-2);
    background-color: var(--surface);
  }
`;

export const Link = styled.a`
  color: var(--on-surface-tertiary);
  font-family: var(--font-sans);
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const LogoutLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
