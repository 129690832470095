import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMText } from '@/components/RMText/RMText';

import { EmailNameContainer, StyledAccountSetupAvatar } from './AccountSetupAvatar.styles';

export interface AccountSetupAvatarProps {
  email: string;
  firstName: string | null;
  lastName: string | null;
}

export function AccountSetupAvatar({ email, firstName, lastName }: AccountSetupAvatarProps) {
  const placeholder = firstName?.length && lastName?.length ? firstName[0] + lastName[0] : email[0];

  return (
    <StyledAccountSetupAvatar>
      <RMAvatar placeholder={placeholder} background="dark" />
      <EmailNameContainer>
        <RMText type="sans" size="s" color="on-surface-primary">
          {firstName} {lastName}
        </RMText>
        <RMText type="sans" size="s" color="on-surface-primary">
          {email}
        </RMText>
      </EmailNameContainer>
    </StyledAccountSetupAvatar>
  );
}
