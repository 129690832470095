import { z } from 'zod';

import { createUserPhoneSchema } from '@/utils/phone-number';

import { Form } from '../form/@types';
import { DeepPartial } from '../form/@types-utils';
import { createForm } from '../form/form';

export function createAccountFormSchema() {
  return z.object({
    firstName: z.string().min(1, { message: 'Enter your first name' }),
    lastName: z.string().min(1, { message: 'Enter your last name' }),
    phone: createUserPhoneSchema().nullish(),
    termsAccepted: z.literal(true),
  });
}

export type AccountFormInput = z.input<ReturnType<typeof createAccountFormSchema>>;
export type AccountFormOutput = z.output<ReturnType<typeof createAccountFormSchema>>;

export type AccountForm = Form<AccountFormInput, AccountFormOutput>;

export function createAccountForm(defaultValues?: DeepPartial<AccountFormInput>): AccountForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createAccountFormSchema(),
  });
}
